@import './_variables.scss';

html,
body,
app-root {
  height: 100%;
}

html {
  position: relative;
  min-height: 100%;
  height: 100%;
}

body {
  font-family: $font-primary;
  position: relative;
  min-height: 100%;
  height: auto;
}

//#region Header
.container-h {
  left: 0%;
  right: 0%;
  top: 0%;
  background: $color-white;
  box-shadow: 0px 7px 28px rgba(98, 79, 131, 0.2);
  z-index: 1;
}

.logoPosition-h {
  height: 40px;
  margin-top: 2px;
}

.title-h {
  font-family: $font-primary;
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 120%;
  text-align: right;
  color: $color-quaternary;
}

.subTitle-h {
  font-family: $font-primary;
  font-style: $font-style-normal;
  font-weight: $font-weight-medium;
  font-size: 18px;
  line-height: 120%;
  text-align: right;
  color: $color-primary;
}

.background-norepeat {
  background-repeat: no-repeat;
}

.Ellipse_0 {
  background-image: $ellipse-0;
}

.Ellipse_1 {
  background-image: $ellipse-1;
}

.Ellipse_2 {
  background-image: $ellipse-2;
}

.Ellipse_3 {
  background-image: $ellipse-3;
}

.Ellipse_4 {
  background-image: $ellipse-4;
}

.Ellipse_5 {
  background-image: $ellipse-5;
}

.Ellipse_6 {
  background-image: $ellipse-6;
}

.Ellipse_7 {
  background-image: $ellipse-7;
}

.Ellipse_8 {
  background-image: $ellipse-8;
}

.Ellipse_9 {
  background-image: $ellipse-9;
}

.Ellipse_10 {
  background-image: $ellipse-10;
}

.modalRoute-h {
  position: absolute;
  left: 5.87%;
  right: 5.6%;
  top: 5.42%;
  bottom: 4.06%;
  background: $color-white;
  box-shadow: 0px 7px 28px rgba(98, 79, 131, 0.25);
  border-radius: 4px;
  z-index: 1000;
}

.buttonCloseModal-h {
  position: absolute;
  top: 1rem;
  right: 1rem;
}

.titleModal-h {
  font-family: $font-primary;
  font-style: $font-style-normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 120%;
  text-align: right;
  color: $color-primary;
}

.subTitleModal-h {
  font-family: $font-primary;
  font-style: $font-style-normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 120%;
  color: $color-gray;
}

.titleSummaryOK-h {
  font-family: $font-primary;
  font-style: $font-style-normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 120%;
  color: $color-primary;
}

.titleSummaryKO-h {
  font-family: $font-primary;
  font-style: $font-style-normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 120%;
  color: $color-gray;
}

.titleStepOK-h {
  font-family: $font-primary;
  font-style: $font-style-normal;
  font-weight: $font-weight-medium;
  font-size: 16px;
  line-height: 120%;
  text-decoration-line: underline;
  color: $color-secondary;
}

.titleStepInProgress-h {
  font-family: $font-primary;
  font-style: $font-style-normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 120%;
  color: $color-primary;
}

.titleStepKO-h {
  font-family: $font-primary;
  font-style: $font-style-normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
  color: $color-gray;
}

.checkSummary {
  width: 20px;
  height: 20px;
  background-image: $checkSummary;
}

.step1Enable {
  width: 20px;
  height: 20px;
  background-image: $enable-1;
}

.step2Disable {
  width: 20px;
  height: 20px;
  background-image: $disable-2;
  outline: 4px white solid;
}

.step2Enable {
  width: 20px;
  height: 20px;
  background-image: $enable-2;
  outline: 4px white solid;
}

.step3Disable {
  width: 20px;
  height: 20px;
  background-image: $disable-3;
  outline: 4px white solid;
}

.step3Enable {
  width: 20px;
  height: 20px;
  background-image: $enable-3;
  outline: 4px white solid;
}

.subStepEnableBackground {
  position: sticky;
  width: 14px;
  height: 14px;
  background: $color-primary;
  padding: 0.31rem;
  padding-left: 0.303rem;
  outline: 8px white solid;
}

.subStepEnableFront {
  position: absolute;
  width: 4px;
  height: 4px;
  background: $color-white;
}

/*#region Step Tracking*/
.stepTracking_bg {
  width: 2px;
  background: $color-primary;
  border-radius: 50px;
  height: 1.8rem;
  margin-left: 0.55rem;
  margin-top: -3px;
}
//#endregion

//#endregion

//#region InfoTop
.container-infotop {
  background: $color-tertiary;
  border-radius: 8px;
}

.title-infotop {
  font-family: $font-primary;
  font-style: $font-style-normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: $color-quaternary;
}

.subtitle-infotop {
  font-family: $font-primary;
  font-style: $font-style-normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 150%;
  color: $color-primary;
}
//#endregion

//#region NextStep
.button-NS {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 32px;
  gap: 10px;
  background: $color-secondary;
  border-radius: 4px;
  color: $color-white;
  border: 1px solid $color-secondary;

  &.p-button-loading {
    gap: 0px;
    > .p-button-label {
      // Keep the original height of the label
      width: 0px;
      overflow: hidden;
      white-space: nowrap;
      flex: none;
      margin: 0;
    }
    > .p-button-icon {
      margin-left: 0px;
    }
  }
}

.p-button:enabled:hover,
.p-button:enabled:active,
.p-button:enabled:focus {
  background: transparent;
  color: $color-gray;
  border-color: $color-secondary;
}

//#endregion

//#region Question
.container-question {
  height: 100%;
}

.title-question {
  font-family: $font-primary;
  font-style: $font-style-normal;
  font-weight: $font-weight-medium;
  font-size: 18px;
  line-height: 120%;

  color: $color-quaternary;
}
//#endregion

//#region ResumeStep
.container-resumestep {
  width: auto;
  height: 82px;
  z-index: 0;
  overflow: auto;
  background: linear-gradient(180deg, rgba(217, 217, 217, 0) 0%, $color-white 26.22%);
}

.container-resumestep::-webkit-scrollbar {
  display: none;
}

.text-resumestep {
  font-family: $font-primary;
  font-style: $font-style-normal;
  font-weight: $font-weight-medium;
  font-size: 16px;
  line-height: 180%;
  text-decoration-line: underline;
  color: $color-secondary;
}

.text-resumestepCurrent {
  font-weight: bolder;
}

@media screen and (max-width: 767px) {
  .oneValue {
    padding: 7% 0%;
  }

  .twoValue {
    padding: 2% 0%;
  }
}

@media screen and (min-width: 768px) {
  .oneValue {
    padding: 2% 0%;
  }

  .twoValue {
    padding: 1% 0%;
  }
}
//#endregion

//#region WebCallBack
.container-WCB {
  position: fixed;
  bottom: 0.5rem;
  right: 0.5rem;
  z-index: 10;
}

.infoModal-WCB {
  background: $color-white;
  box-shadow: $box-shadow;
  border-radius: 4px;
  width: 284px;
  height: 239px;
  border: $border-dropdown 1px solid;
}

.title-header {
  text-align: center;
  color: $color-quaternary;
}

.button-WCB {
  background: $color-secondary;
  box-shadow: $box-shadow;
  border-radius: 50px;
  height: 50px;
  width: 50px;
  position: fixed;
  right: 10px;
  bottom: 10px;
}

.button-WCB:focus {
  border: $color-white 1px solid;
}

.title-WCB {
  font-family: $font-primary;
  font-style: $font-style-normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  text-align: center;
  color: $color-quaternary;
  margin-top: 0.5rem;
}

.button-CB {
  box-sizing: border-box;
  width: 214px;
  height: 45px;
  border: 2px solid $color-white;
  border-radius: 4px;
  background: $color-secondary;
  box-shadow: $box-shadow;
  border-radius: 4px;
}

.titleButton-CB {
  font-family: $font-primary;
  font-style: $font-style-normal;
  font-weight: $font-weight-medium;
  font-size: 20px;
  line-height: 120%;
  color: $color-white;
}
//#endregion

//#region infoBulle
.containerPopUpInfo {
  min-height: 3rem;
  background: $color-white;
  box-shadow: $box-shadow-purple;
  border-radius: 4px;
  width: 350px;
}

.p-overlaypanel:after,
.p-overlaypanel:before {
  display: none;
}

.popupPosition {
  margin-bottom: 8px;
  margin-left: 2px;
  display: inline-block;
}
//#endregion

//#region Insurance Benefit
.textPanel-R {
  font-family: $font-primary;
  font-style: $font-style-normal;
  font-weight: $font-weight-medium;
  font-size: 13px;
  line-height: 150%;
  color: $color-primary;
}

.textBPanel-R {
  font-family: $font-primary;
  font-style: $font-style-normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 150%;
  color: $color-secondary;
}

.textIPanel-R {
  font-family: $font-primary;
  font-style: $font-style-normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: $color-quaternary;
}

.textDescription {
  font-family: $font-primary;
  font-style: $font-style-normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: $color-quaternary;
}

.chipListPosition-R {
  height: 22px;
  margin-top: 6px;
}

.arrowPosition-R {
  height: 6px;
  margin-top: -4px;
  margin-left: 8px;
}

.button-chevron {
  width: 25px;
  height: 25px;
}
//#endregion

//#region Footer
@media (width >= 768px) {
  .position-footer {
    position: absolute;
    bottom: 0.5rem;
    background: #fff;
  }
}

@media (width < 768px) {
  .position-footer {
    position: absolute;
    bottom: 2rem;
    background: #fff;
  }
}
//#endregion

.width-max {
  width: 100% !important;
}

.pButtonToggle {
  padding: 60px 20px;
}
