@import './_variables.scss';

//#region lessor Certificate
.text-LC {
  font-family: $font-primary;
  font-style: $font-style-normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 120%;
  color: $color-quaternary;
}

.popup-LC {
  margin-left: 4px;
  margin-top: 2px;
}
//#endregion

//#region Services
.panelSV.inactive {
  height: 100%;
  background: $color-white;
  border: 2px solid $color-white;
  box-shadow: 0px 7px 28px rgba(98, 79, 131, 0.1);
  border-radius: 4px;
}

.panelSV.active {
  height: 100%;
  background: $color-white;
  border: 2px solid $color-primary;
  box-shadow: 0px 7px 28px rgba(98, 79, 131, 0.1);
  border-radius: 4px;
}

.textPanel-SV {
  font-family: $font-primary;
  font-style: $font-style-normal;
  font-weight: $font-weight-medium;
  font-size: 18px;
  line-height: 150%;
  color: $color-primary;
}

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider,
.p-inputswitch.p-inputswitch-checked:not(.p-disabled):hover .p-inputswitch-slider {
  background: $color-primary;
}

.p-inputswitch.p-focus .p-inputswitch-slider {
  box-shadow: 0 0 0 0.2rem rgba(71, 37, 131, 0.25);
}

.textModalStep {
  font-family: $font-primary;
  font-style: $font-style-normal;
  font-weight: $font-weight-medium;
  font-size: 16px;
  line-height: 150%;
  color: $color-primary;
}

.textBModalStep {
  font-family: $font-primary;
  font-style: $font-style-normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
  color: $color-secondary;
  text-decoration: underline;
}

.button-outline-call-SV {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 12px;
  gap: 10px;
  background: $color-white;
  border: 2px solid $color-secondary;
  border-radius: 4px;

  font-family: $font-primary;
  font-style: $font-style-normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 150%;
  color: $color-secondary;
}

.button-outline-call-SV.p-button:enabled:hover,
.button-outline-call-SV.p-button:enabled:active,
.button-outline-call-SV.p-button:enabled:focus {
  background: $color-white;
  color: $color-secondary;
  border-color: $color-secondary;
}

.button-SV {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 40px;
  font-size: 14px;
  gap: 10px;
  background: $color-secondary;
  border-radius: 4px;
  color: $color-white;
  border: 1px solid $color-secondary;
}
//#endregion

//#region Cancellation
.buttonToggleSM {
  width: 100%;
  height: 48px;
  box-shadow: $box-shadow-purple;
  border-radius: 4px;
}

.buttonToggleSM span.p-button-label {
  font-family: $font-primary;
  font-style: $font-style-normal;
  font-weight: $font-weight-medium;
  font-size: 18px;
  line-height: 150%;
}

.buttonToggleSM,
.buttonToggleSM.inactive .buttonToggleSM.inactive:active,
.buttonToggleSM.inactive:focus {
  background: $color-white !important;
  color: $color-primary !important;
  border: $color-primary 1px solid !important;
}

.buttonToggleSM.active,
.buttonToggleSM.active:active,
.buttonToggleSM.active:hover,
.buttonToggleSM.active:focus,
.buttonToggleSM.inactive:hover {
  background: $color-primary !important;
  color: $color-white !important;
  border: $color-white 1px solid !important;
}

.textDate {
  font-family: $font-primary;
  font-style: $font-style-normal;
  font-weight: $font-weight-medium;
  font-size: 22px;
  line-height: 120%;
  color: $color-primary;
}
//#endregion

//#region Payment

.p-inputmask {
  &::placeholder {
    color: transparent;
  }
}

.p-inputtext.p-inputmask,
.p-inputtext,
.p-dropdown {
  width: 100%;
}

.p-input-text {
  width: 100%;
  font-family: $font-primary;
  font-style: $font-style-normal;
  font-weight: 400;
  font-size: 5px;
  line-height: 150%;
  color: $color-gray;
}

.p-float-label .p-inputwrapper-focus ~ label.mask,
.p-float-label .p-inputwrapper-filled ~ label.mask,
.p-float-label .p-inputwrapper-focus ~ label.calendar,
.p-float-label .p-inputwrapper-filled ~ label.calendar,
.p-float-label .p-inputwrapper-focus ~ label.dropdown,
.p-float-label .p-inputwrapper-filled ~ label.dropdown,
.p-float-label input.p-focus ~ label.inputtext,
.p-float-label input.p-filled ~ label.inputtext,
.p-float-label input:focus ~ label {
  top: -0.2rem;
  background-color: $color-white;
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 3px;
  font-family: $font-primary;
  font-style: $font-style-normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  z-index: 1;
  color: $color-gray;
}

.p-autocomplete-dd {
  .p-autocomplete-input {
    border-right: none !important;
    &:hover {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: none;
      border-top: 1px $color-primary solid !important;
      border-left: 1px $color-primary solid !important;
      border-bottom: 1px $color-primary solid !important;
    }

    &:focus {
      &::placeholder {
        color: inherit;
      }
    }

    &::placeholder {
      color: transparent;
    }
  }

  &:hover {
    .p-autocomplete-dropdown {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: none;
      border-top: 1px $color-primary solid !important;
      border-right: 1px $color-primary solid !important;
      border-bottom: 1px $color-primary solid !important;
    }

    input {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: none;
      border-top: 1px $color-primary solid !important;
      border-left: 1px $color-primary solid !important;
      border-bottom: 1px $color-primary solid !important;
    }
  }
}

.p-inputtext:enabled:hover,
.p-inputtext:enabled:active {
  border-color: $color-primary;
}

.p-inputtext:enabled:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem $color-tertiary;
  border-color: $color-primary;
}

.inputtext-placeholder {
  &:focus {
    &::placeholder {
      color: inherit;
    }
  }

  &::placeholder {
    color: transparent;
  }
}

.p-dropdown:not(.p-disabled).p-focus {
  outline: 0 none;
  outline-offset: 0;
  border-color: $color-primary;
  box-shadow: 0 0 0 0.2rem $color-tertiary;
}

.p-dropdown:not(.p-disabled):hover {
  border-color: $color-primary;
}

.p-dropdown {
  background: $color-white;
  border: 1px solid #d1d5db;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  border-radius: 6px;

  .p-inputtext {
    border: none;
  }
}

.p-float-label {
  width: 100%;
}

.p-float-label > .ng-invalid.ng-dirty + label {
  color: $color-gray;
}

.p-calendar {
  width: 100%;
  font-family: $font-primary;
  font-style: $font-style-normal;
  font-weight: 400;
  font-size: 5px;
  line-height: 150%;
  color: $color-gray;
}

.p-autocomplete-dropdown {
  border: 1px solid $border-dropdown !important;
  border-left: none !important;
  background-color: transparent;
}

::ng-deep .p-button-icon-only:focus,
::ng-deep .p-button-icon-only:enabled:focus {
  box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
}

.p-inputtext {
  width: 100%;
  font-family: $font-primary;
  font-style: $font-style-normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: $color-gray;
  border: 1px solid $border-dropdown;
  padding: 0.75rem 0.75rem;
}

.p-button.p-button-icon-only {
  color: $color-gray;
}

.title-P {
  font-family: $font-primary;
  font-style: $font-style-normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 120%;
  color: $color-quaternary;
}

.text-P {
  font-family: $font-primary;
  font-style: $font-style-normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: $color-quaternary;
}

.text-Pmin {
  font-family: $font-primary;
  font-style: $font-style-normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  color: $color-quaternary;
}

.textB-P {
  font-family: $font-primary;
  font-style: $font-style-normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 150%;
  color: $color-quaternary;
}

.button-C {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 32px;
  gap: 10px;
  background: $color-white;
  border-radius: 4px;
  color: $color-quaternary;
  border: 1px solid $color-white;
}

.button-C:hover {
  background: $color-white;
  border: 1px solid $color-white;
}

.button-C:focus {
  background: $color-white;
  border: 1px solid $color-white;
}

.button-C:active {
  background: $color-white;
  border: 1px solid $color-white;
}

.iconSideLabel span.p-button-label {
  flex: none;
}
//#endregion

//#region Signature Contract
.backgroundIFrame-ES {
  background: $color-white;
  box-shadow: $box-shadow;
  border-radius: 4px;
  //width: 327px;
  height: 380px;
  position: relative;
}

.text-ES {
  font-family: $font-primary;
  font-style: $font-style-normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 120%;
  color: $color-gray;
}

.textB-ES {
  font-family: $font-primary;
  font-style: $font-style-normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 120%;
  color: $color-secondary;
  text-decoration: underline;
}

.textCodeError-ES {
  font-family: $font-primary;
  font-style: $font-style-normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: $color-quaternary;
}

.button-outline-call-ES,
.button-outline-call-ES:hover,
.button-outline-call-ES:focus,
.button-outline-call-ES:active {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 12px;
  gap: 10px;
  background: $color-white;
  border: 2px solid $color-secondary;
  border-radius: 4px;

  font-family: 'Wigrum';
  font-style: $font-style-normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 150%;
  color: $color-secondary;
}

.backgroundTextTab-ES {
  background: $color-white;
  padding: 1rem;
  box-shadow: $box-shadow;
  border-radius: 4px;
}

.titleTextTab-ES {
  font-family: $font-primary;
  font-style: $font-style-normal;
  font-weight: $font-weight-medium;
  font-size: 28px;
  line-height: 150%;
  color: $color-quaternary;
}

.textTab-ES {
  font-family: $font-primary;
  font-style: $font-style-normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: $color-quaternary;
}

.backgroundButton-ES {
  width: 100%;
  height: 53px;
  background: $color-tertiary;
  border-radius: 4px;
  font-family: $font-primary;
  font-style: $font-style-normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 120%;
  text-align: center;
  color: $color-primary;
}

.activeP-ES {
  height: 43px;
  font-family: $font-primary;
  font-style: $font-style-normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  text-align: center;
  color: $color-white;
  background: $color-primary;
  border-radius: 4px;
}

.inactiveP-ES {
  height: 43px;
  font-family: $font-primary;
  font-style: $font-style-normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  text-align: center;
  color: $color-primary;
}

.inputCode-ES {
  width: 47px !important;
  height: 47px !important;
  background: $color-white;
  box-shadow: $box-shadow-purple;
  border-radius: 4px;

  font-family: $font-primary;
  font-style: $font-style-normal;
  font-weight: $font-weight-medium;
  font-size: 28px;
  line-height: 120%;
  color: $color-quaternary;
  padding: 15px;
  box-sizing: border-box;
  border-width: 1px;
  border-style: solid;
  border-color: #e5e7eb;
}

scrollPanel {
  height: 380px;
}

.p-scrollpanel-bar.p-scrollpanel-bar-y {
  opacity: 1;
  margin-left: 8px;
  background: $color-primary;
  width: 5px;
}

.p-checkbox .p-checkbox-box.p-highlight {
  border-color: $color-secondary;
  background: $color-secondary;
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
  border-color: $color-secondary;
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem $color-tertiary;
  border-color: $color-secondary;
}

.p-checkbox .p-checkbox-box {
  border-color: $color-secondary;
  border-width: 1px;
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
  border-color: $color-secondary;
  background: $color-secondary;
  color: $color-white;
}
//#endregion

//#region Confirmation Contrat
.containerPanel-CC {
  width: 332px;
  height: 380px;
  background: $color-white;
  box-shadow: $box-shadow;
  border-radius: 4px;
}

.containerText-CC {
  background-image: $confirmContrat;
  background-repeat: no-repeat;
  height: 239px;
}

.containerText-CC .title-CC {
  font-family: $font-primary;
  font-style: $font-style-normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 120%;
  color: $color-white;
}

.containerText-CC .subtitle-CC {
  font-family: $font-primary;
  font-style: $font-style-normal;
  font-weight: $font-weight-medium;
  font-size: 16px;
  line-height: 120%;
  text-align: center;
  color: $color-white;
}

.containerTextSB-CC {
  font-family: $font-primary;
  font-style: $font-style-normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: $color-quaternary;
}

.containerTextSB-CC .textB-CC {
  font-family: $font-primary;
  font-style: $font-style-normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 150%;
  color: $color-primary;
}

.titleDoc-CC {
  font-family: $font-primary;
  font-style: $font-style-normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 120%;
  color: $color-quaternary;
}

.containerSubtitleDoc-CC {
  width: 332px;
  height: 53px;
  background: $color-white;
  box-shadow: $box-shadow-purple;
  border-radius: 4px;
  cursor: pointer;
}

.subtitleDoc-CC {
  font-family: $font-primary;
  font-style: $font-style-normal;
  font-weight: $font-weight-medium;
  font-size: 14px;
  line-height: 150%;
  color: $color-quaternary;
}
//#endregion

.active-ES {
  height: 43px;
  font-family: $font-primary;
  font-style: $font-style-normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 120%;
  text-align: center;
  color: $color-white;
  background: $color-primary;
  border-radius: 4px;
}

.inactive-ES {
  height: 43px;
  font-family: $font-primary;
  font-style: $font-style-normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 120%;
  text-align: center;
  color: $color-primary;
}

.inactiveP-ES:disabled {
  opacity: 0.4;
}
