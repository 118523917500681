//#region Color Variables
$color-white: #ffffff;
$color-primary: #472583;
$color-primary-light: #f3eeff;
$color-secondary: #e94b64;
$color-tertiary: #f3eeff;
$color-quaternary: #201d24;
$color-gray: #727272;
$color-black: #3c3c3b;

$box-shadow: 0px 7px 28px rgba(98, 79, 131, 0.25);
$box-shadow-purple: 0px 7px 28px rgba(98, 79, 131, 0.1);
$border-dropdown: #ced4da;
//#endregion

//#region Font Variables
$font-primary: 'Wigrum';

$font-italic-woff2: url('../font/Italic/Wigrum-Italic.woff') format('woff2');
$font-italic-woff: url('../font/Italic/Wigrum-Italic.woff') format('woff');
$font-light-italic-woff2: url('../font/Light/Wigrum-LightItalic.woff2') format('woff2');
$font-light-italic-woff: url('../font/Light/Wigrum-LightItalic.woff') format('woff');
$font-medium-woff2: url('../font/Medium/Wigrum-Medium.woff2') format('woff2');
$font-medium-woff: url('../font/Medium/Wigrum-Medium.woff') format('woff');
$font-light-woff2: url('../font/Light/Wigrum-Light.woff2') format('woff2');
$font-light-woff: url('../font/Light/Wigrum-Light.woff') format('woff');
$font-black-italic-woff2: url('../font/Black/Wigrum-BlackItalic.woff2') format('woff2');
$font-black-italic-woff: url('../font/Black/Wigrum-BlackItalic.woff') format('woff');
$font-medium-italic-woff2: url('../font/Medium/Wigrum-MediumItalic.woff2') format('woff2');
$font-medium-italic-woff: url('../font/Medium/Wigrum-MediumItalic.woff') format('woff');
$font-bold-woff2: url('../font/Bold/Wigrum-Bold.woff2') format('woff2');
$font-bold-woff: url('../font/Bold/Wigrum-Bold.woff') format('woff');
$font-bold-italic-woff2: url('../font/Bold/Wigrum-BoldItalic.woff2') format('woff2');
$font-bold-italic-woff: url('../font/Bold/Wigrum-BoldItalic.woff') format('woff');
$font-black-woff2: url('../font/Black/Wigrum-Black.woff2') format('woff2');
$font-black-woff: url('../font/Black/Wigrum-Black.woff') format('woff');
$font-regular-woff2: url('../font/Regular/Wigrum-Regular.woff2') format('woff2');
$font-regular-woff: url('../font/Regular/Wigrum-Regular.woff') format('woff');

$font-weight-light: 300;
$font-weight-regular: normal;
$font-weight-medium: 500;
$font-weight-bold: bold;
$font-weight-black: 900;

$font-style-normal: normal;
$font-style-italic: italic;
$font-display-swap: swap;
//#endregion

//#region Image Variables
$ellipse-0: url('../Images/Header/Ellipse_0.svg');
$ellipse-1: url('../Images/Header/Ellipse_1.svg');
$ellipse-2: url('../Images/Header/Ellipse_2.svg');
$ellipse-3: url('../Images/Header/Ellipse_3.svg');
$ellipse-4: url('../Images/Header/Ellipse_4.svg');
$ellipse-5: url('../Images/Header/Ellipse_5.svg');
$ellipse-6: url('../Images/Header/Ellipse_6.svg');
$ellipse-7: url('../Images/Header/Ellipse_7.svg');
$ellipse-8: url('../Images/Header/Ellipse_8.svg');
$ellipse-9: url('../Images/Header/Ellipse_9.svg');
$ellipse-10: url('../Images/Header/Ellipse_10.svg');

$checkSummary: url('../Images/Header/CheckSummary.svg');
$enable-1: url('../Images/Header/1-Enable.svg');
$disable-2: url('../Images/Header/2-Disable.svg');
$enable-2: url('../Images/Header/2-Enable.svg');
$disable-3: url('../Images/Header/3-Disable.svg');
$enable-3: url('../Images/Header/3-Enable.svg');

$backgroundPanelCPlus: url('../Images/Devis/BackgroundPanelCPlus.svg');
$panelCPlusLogo: url('../Images/Devis/PanelCPlus.svg');
$panelPLogo: url('../Images/Devis/PanelP.svg');
$panelTLogo: url('../Images/Devis/Logo_Tourcoing.svg');
$panelLLogo: url('../Images/Devis/Logo_Lille.svg');
$panelSLogo: url('../Images/Devis/PanelS.svg');
$backgroundPanelC: url('../Images/Devis/BackgroundPanelC.svg');
$panelCLogo: url('../Images/Devis/PanelC.svg');
$backgroundPanelCPlus_R: url('../Images/Devis/BackgroundPanelCPlus_R.svg');
$backgroundPanelCPlus_NR: url('../Images/Devis/BackgroundPanelCPlus_NR.svg');
$backgroundPanelC_R: url('../Images/Devis/BackgroundPanelC_R.svg');
$backgroundPanelC_NR: url('../Images/Devis/BackgroundPanelC_NR.svg');
$backgroundPanelPS_R: url('../Images/Devis/BackgroundPanelC_PS_R.svg');

$titleCPlus: url('../Images/Devis/TitleCPlus.svg');
$titleC: url('../Images/Devis/TitleC.svg');
$titleP: url('../Images/Devis/TitleP.svg');
$titleT: url('../Images/Devis/Logo_Tourcoing.svg');
$titleL: url('../Images/Devis/Logo_Lille.svg');
$titleS: url('../Images/Devis/TitleS.svg');
$infoPhone: url('../Images/Devis/InfoPhone.svg');
$trash: url('../Images/Devis/Trash.svg');

$house: url('../Images/Housing/house.svg');
$houseWhite: url('../Images/Housing/house_white.svg');
$appartment: url('../Images/Housing/flat.svg');
$appartmentWhite: url('../Images/Housing/flat_white.svg');
$other: url('../Images/Housing/other.svg');
$otherWhite: url('../Images/Housing/other_white.svg');
$owner: url('../Images/Housing/owner.svg');
$ownerWhite: url('../Images/Housing/owner_white.svg');
$tenant: url('../Images/Housing/tenant.svg');
$tenantWhite: url('../Images/Housing/tenant_white.svg');
$primary: url('../Images/Housing/primary.svg');
$primaryWhite: url('../Images/Housing/primary_white.svg');
$secondary: url('../Images/Housing/secondary.svg');
$secondaryWhite: url('../Images/Housing/secondary_white.svg');

$confirmContrat: url('../Images/Contract/BackgroundPanel.svg');
//#endregion
