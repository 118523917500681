@tailwind base;
@tailwind components;
@tailwind utilities;
@import './_variables.scss';
@import './components.scss';
@import './contract.scss';
@import './devis.scss';
@import './layout.scss';

@font-face {
  font-family: $font-primary;
  src: $font-italic-woff2, $font-italic-woff;
  font-weight: $font-weight-regular;
  font-style: $font-style-italic;
  font-display: $font-display-swap;
}

@font-face {
  font-family: $font-primary;
  src: $font-light-italic-woff2, $font-light-italic-woff;
  font-weight: $font-weight-light;
  font-style: $font-style-italic;
  font-display: $font-display-swap;
}

@font-face {
  font-family: $font-primary;
  src: $font-medium-woff2, $font-medium-woff;
  font-weight: $font-weight-medium;
  font-style: $font-style-normal;
  font-display: $font-display-swap;
}

@font-face {
  font-family: $font-primary;
  src: $font-light-woff2, $font-light-woff;
  font-weight: $font-weight-light;
  font-style: $font-style-normal;
  font-display: $font-display-swap;
}

@font-face {
  font-family: $font-primary;
  src: $font-black-italic-woff2, $font-black-italic-woff;
  font-weight: $font-weight-black;
  font-style: $font-style-italic;
  font-display: $font-display-swap;
}

@font-face {
  font-family: $font-primary;
  src: $font-medium-italic-woff2, $font-medium-italic-woff;
  font-weight: $font-weight-medium;
  font-style: $font-style-italic;
  font-display: $font-display-swap;
}

@font-face {
  font-family: $font-primary;
  src: $font-bold-woff2, $font-bold-woff;
  font-weight: $font-weight-bold;
  font-style: $font-style-normal;
  font-display: $font-display-swap;
}

@font-face {
  font-family: $font-primary;
  src: $font-bold-italic-woff2, $font-bold-italic-woff;
  font-weight: $font-weight-bold;
  font-style: $font-style-italic;
  font-display: $font-display-swap;
}

@font-face {
  font-family: $font-primary;
  src: $font-black-woff2, $font-black-woff;
  font-weight: $font-weight-black;
  font-style: $font-style-normal;
  font-display: $font-display-swap;
}

@font-face {
  font-family: $font-primary;
  src: $font-regular-woff2, $font-regular-woff;
  font-weight: $font-weight-regular;
  font-style: $font-style-normal;
  font-display: $font-display-swap;
}
