@import './_variables.scss';

//#region Preferential Rate
.clickable-card {
  display: flex;
  flex-direction: column;
  margin-top: 1.5rem;

  max-width: 332px;
  background: $color-white;
  /* VYV Shadow */
  box-shadow: $box-shadow-purple;
  border-radius: 4px;
  border-radius: 4px;
  font-family: 'Wigrum';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 150%;
  background: $color-white;
  color: $color-primary;

  &:hover,
  &:focus,
  &.selected,
  &.active {
    background: $color-primary;
    color: $color-white;

    div.house {
      background: $houseWhite;
      background-repeat: no-repeat;
    }

    div.appartment {
      background: $appartmentWhite;
      background-repeat: no-repeat;
    }

    div.other {
      background: $otherWhite;
      background-repeat: no-repeat;
    }

    div.owner {
      background: $ownerWhite;
      background-repeat: no-repeat;
    }

    div.tenant {
      background: $tenantWhite;
      background-repeat: no-repeat;
    }

    div.primary {
      background: $primaryWhite;
      background-repeat: no-repeat;
    }

    div.secondary {
      background: $secondaryWhite;
      background-repeat: no-repeat;
    }
  }

  div.house {
    background: $house;
    background-repeat: no-repeat;
    margin-top: 2rem;
    margin-bottom: 1rem;
    height: 45px;
    width: 45px;
  }

  div.appartment {
    background: $appartment;
    background-repeat: no-repeat;
    margin-top: 2rem;
    margin-bottom: 1rem;
    height: 45px;
    width: 45px;
  }

  div.other {
    background: $other;
    background-repeat: no-repeat;
    margin-top: 2rem;
    margin-bottom: 1rem;
    height: 45px;
    width: 45px;
  }

  div.owner {
    background: $owner;
    background-repeat: no-repeat;
    margin-top: 2rem;
    margin-bottom: 1rem;
    height: 45px;
    width: 45px;
  }

  div.tenant {
    background: $tenant;
    background-repeat: no-repeat;
    margin-top: 2rem;
    margin-bottom: 1rem;
    height: 45px;
    width: 45px;
  }

  div.primary {
    background: $primary;
    background-repeat: no-repeat;
    margin-top: 2rem;
    margin-bottom: 1rem;
    height: 45px;
    width: 45px;
  }

  div.secondary {
    background: $secondary;
    background-repeat: no-repeat;
    margin-top: 2rem;
    margin-bottom: 1rem;
    height: 45px;
    width: 45px;
  }
}

.button-icon {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: $color-white;
  border: 2px solid $color-secondary;
  border-radius: 4px;
  height: 32px !important;
  width: 32px !important;
  font-family: $font-primary;
  font-style: $font-style-normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 150%;
  color: $color-secondary;
  padding: 0 !important;

  &.inactive,
  .inactive:active,
  .inactive:focus,
  .inactive:hover {
    background: $color-white;
    color: $color-primary;
    border: $color-primary 1px solid;
  }

  &.active,
  .active:active,
  .active:focus,
  .active:hover {
    background: $color-primary;
    color: $color-white;
    border: $color-white 1px solid;
  }
}

.input-number {
  background: #ffffff;
  /* VYV Shadow */
  box-shadow: 0px 7px 28px rgba(98, 79, 131, 0.1);
  border-radius: 4px !important;
  font-family: 'Wigrum';
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 120%;
  width: 192px;
  text-align: center;
  &:focus,
  &:focus {
    outline: none;
  }

  padding: none !important;
  border: none !important;
  transition: none !important;
  -webkit-appearance: none !important;
  appearance: none !important;
  flex: none !important;
}
//#endregion

//#region Subscriber
.containerInfoPhone {
  min-height: 75px;
  max-height: 112px;
  background: $color-white;
  box-shadow: $box-shadow-purple;
  border-radius: 4px;
}

.containerLeftInfoPhone {
  width: 8px;
  min-height: 75px;
  max-height: 112px;
  background: $color-primary;
  border-radius: 4px 0px 0px 4px;
}

.iconInfoPhone {
  background-image: $infoPhone;
  background-repeat: no-repeat;
  width: 65px;
}

.textInfoPhone {
  font-family: $font-primary;
  font-style: $font-style-normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 150%;
  color: $color-gray;
  margin-top: 5px;
}

.textInfoBottom {
  font-family: $font-primary;
  font-style: $font-style-normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 150%;
  color: $color-gray;
}

.textInfoBottomError {
  color: #e24c4c !important;
}

.textBInfoBottom {
  font-family: $font-primary;
  font-style: $font-style-normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 150%;
  color: $color-secondary;
  text-decoration: underline;
}

.p-float-label label.birthday {
  margin-right: 0.8rem;
  font-size: 13px;
}

.p-float-label .p-inputwrapper-focus ~ label.calendar.birthday,
.p-float-label .p-inputwrapper-filled ~ label.calendar.birthday {
  font-size: 12px;
}
//#endregion

//#region Rate
.containerPanel-R {
  width: 332px;
  min-height: 400px;
  background: $color-white;
  box-shadow: $box-shadow;
  border-radius: 4px;
}

.promoCode {
  font-family: $font-primary;
  font-style: $font-style-normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: $color-quaternary;
}

.button-NS.p-button.p-button-icon-only {
  width: 3rem;
  padding: 0.75rem 1rem;

  > .p-button-label {
    display: none;
  }
}

.title-R {
  font-family: $font-primary;
  font-style: $font-style-normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 120%;
  color: $color-white;
}

.panelCPlus-R {
  background-image: $backgroundPanelCPlus_R;
  background-repeat: no-repeat;
}

.panelCPS-R {
  background-image: $backgroundPanelPS_R;
  background-repeat: no-repeat;
  height: 177px;
}

.borderRecommanded {
  outline: 2px dashed $color-secondary;
}

@media (width >= 768px) {
  .topNotRecommandedC {
    margin-top: 1.7rem;
  }

  .topNotRecommandedCPlus {
    margin-top: 1.85rem;
    margin-bottom: 1.35rem;
  }

  .clickable-card-parent {
    display: flex;
    gap: 1rem;
    margin-top: 4rem;
  }

  .clickable-card {
    padding: 10px;
  }

  .clickable-card-grid {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: center;
  }

  .clickable-grid {
    width: 13rem;
  }

  .text-clickable-card {
    word-wrap: break-word;
    text-align: center;
  }

  .clickable-card-text {
    white-space: pre;
  }
}

@media (width < 768px) {
  .containerMinHeight {
    min-height: 0px;
  }

  .clickable-card-parent {
    flex-direction: column;
  }

  .clickable-card-grid {
    display: grid;
    row-gap: 1rem;
  }

  .clickable-grid {
    margin-left: auto;
    margin-right: auto;
  }
}

.recommanded {
  .panel-offer {
    height: 239px;
  }

  .panelCPlus {
    background-image: $backgroundPanelCPlus_R;
    background-repeat: no-repeat;
    @apply text-purple;
  }

  .panelC {
    background-image: $backgroundPanelC_R;
    background-repeat: no-repeat;
    color: #ffffff;
  }

  .title-R {
    color: $color-secondary !important;
    font-weight: bold !important;
    background-color: white;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 0.5rem;
    text-align: center;
    border-radius: 0.25rem;
  }
}

.not-recommanded {
  .panel-offer {
    @apply pt-4;
    height: 239px;
  }

  .panelCPlus {
    background-image: $backgroundPanelCPlus_NR;
    background-repeat: no-repeat;
    height: 212px;
  }

  .panelC {
    background-image: $backgroundPanelC_NR;
    background-repeat: no-repeat;
    height: 212px;
  }
}

.panelCPLogo-R {
  background-image: $panelPLogo;
  background-repeat: no-repeat;
  height: 50px;
}

.panelCTLogo-R {
  background-image: $panelTLogo;
  background-repeat: no-repeat;
  height: 50px;
}

.panelCLLogo-R {
  background-image: $panelLLogo;
  background-repeat: no-repeat;
  height: 50px;
}

.panelCSLogo-R {
  background-image: $panelSLogo;
  background-repeat: no-repeat;
  height: 50px;
}

.benefitsCPlusBottomPart {
  background-color: $color-primary-light;
}

.benefitsCPlusLogo {
  width: 1.5rem;
  height: 1.5rem;
  position: absolute;
  left: 48%;
  bottom: -11px;
}

.groupPromo {
  align-items: center;
}

.iconPromo {
  flex-shrink: 0;
}

.textPromo {
  font-family: $font-primary;
  font-style: $font-style-normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 150%;
  color: $color-quaternary;
}

.textBPromo {
  font-family: $font-primary;
  font-style: $font-style-normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 150%;
  color: $color-primary;
}

.titlePanel-R {
  font-family: $font-primary;
  font-style: $font-style-normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 150%;
  color: $color-quaternary;
}

.subtitlePanel-R {
  font-family: $font-primary;
  font-style: $font-style-normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: $color-gray;
}

.detailsBenefit {
  font-family: $font-primary;
  font-style: $font-style-normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  text-decoration-line: underline;
  color: $color-secondary;
}

//#endregion

//#region Offer Customization
.container-OC {
  height: 100px;
  background: $color-primary;
}

.containerC-OC {
  height: 100px;
  background: $color-primary;
}

.panelCPlusLogo-OC {
  background-image: $titleCPlus;
  background-repeat: no-repeat;
  width: 80px;
  height: 36px;
}

.panelCLogo-OC {
  background-image: $panelCLogo;
  background-repeat: no-repeat;
  background-size: 55%;
  width: 80px;
  height: 36px;
}

.panelPLogo-OC {
  background-image: $titleP;
  background-repeat: no-repeat;
  width: 80px;
  height: 41px;
}

.panelTLogo-OC {
  background-image: $titleT;
  background-repeat: no-repeat;
  width: 80px;
  height: 48px;
}

.panelLLogo-OC {
  background-image: $titleL;
  background-repeat: no-repeat;
  width: 80px;
  height: 48px;
}

.panelSLogo-OC {
  background-image: $titleS;
  background-repeat: no-repeat;
  width: 80px;
  height: 39px;
}

.rate-OC {
  font-family: $font-primary;
  font-style: $font-style-normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 120%;
  color: $color-white;
}

.rateC-OC {
  font-family: $font-primary;
  font-style: $font-style-normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 120%;
  color: $color-white;
}

.textMonth-OC {
  font-family: $font-primary;
  font-style: $font-style-normal;
  font-weight: $font-weight-medium;
  font-size: 13px;
  line-height: 120%;
  color: $color-white;
}

.textMonthC-OC {
  font-family: $font-primary;
  font-style: $font-style-normal;
  font-weight: $font-weight-medium;
  font-size: 13px;
  line-height: 120%;
  color: $color-white;
}

.containerOption {
  min-height: 129px;
  background: $color-white;
  box-shadow: $box-shadow-purple;
  border-radius: 0px 0px 32px 32px;
}

.textOption {
  font-family: $font-primary;
  font-style: $font-style-normal;
  font-weight: $font-weight-medium;
  font-size: 14px;
  line-height: 150%;
  color: $color-secondary;
}

.textBOption {
  font-family: $font-primary;
  font-style: $font-style-normal;
  font-weight: $font-weight-medium;
  font-size: 16px;
  line-height: 120%;
  color: $color-primary;
}

.buttonOption {
  width: 100%;
  height: 48px;
  box-shadow: $box-shadow-purple;
  border-radius: 4px;
}

.buttonOption span.p-button-label {
  font-family: $font-primary;
  font-style: $font-style-normal;
  font-weight: $font-weight-medium;
  font-size: 18px;
  line-height: 120%;
  text-align: left;
}

.buttonOption,
.buttonOption.inactive .buttonOption.inactive:active,
.buttonOption.inactive:focus,
.buttonOption.inactive:hover {
  background: $color-white !important;
  color: $color-primary !important;
  border: $color-primary 1px solid !important;
}

.buttonOption.active,
.buttonOption.active:active,
.buttonOption.active:focus,
.buttonOption.active:hover {
  background: $color-primary !important;
  color: $color-white !important;
  border: $color-white 1px solid !important;
}

.textPanelOpt {
  font-family: $font-primary;
  font-style: $font-style-normal;
  font-weight: $font-weight-medium;
  font-size: 18px;
  line-height: 120%;
  color: $color-primary;
}

.textDetailsPanelOpt {
  font-family: $font-primary;
  font-style: $font-style-italic;
  font-weight: 400;
  font-size: 18px;
  line-height: 120%;
  color: $color-primary;
}

.textBPanelOpt {
  font-family: $font-primary;
  font-style: $font-style-normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: $color-primary;
}

.trash {
  width: 16px;
  height: 18px;
  background: $trash;
  background-repeat: no-repeat;
  display: block;
}
//#endregion

//#region School Insurance
.containerForm-SI {
  width: 335px;
  min-height: 115px;
  max-height: 335px;
  background: $color-white;
  box-shadow: $box-shadow-purple;
  border-radius: 4px;
}
.title-SI {
  font-family: $font-primary;
  font-style: $font-style-normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 120%;
  color: $color-primary;
}

.text-SI {
  font-family: $font-primary;
  font-style: $font-style-normal;
  font-weight: $font-weight-medium;
  font-size: 16px;
  line-height: 120%;
  color: #201d24;
}

.textHeadColumn {
  font-family: $font-primary;
  font-style: $font-style-normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 120%;
  color: $color-gray;
}

.textColumn {
  font-family: $font-primary;
  font-style: $font-style-normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: $color-quaternary;
}
//#endregion

//#region Quote Confirmation
.titleQuote {
  font-family: $font-primary;
  font-style: $font-style-normal;
  font-weight: $font-weight-medium;
  font-size: 18px;
  line-height: 120%;
  color: $color-quaternary;
}

.subtitleQuote {
  font-family: $font-primary;
  font-style: $font-style-normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 120%;
  color: $color-primary;
}

.containerPanel-QC {
  width: 332px;
  min-height: 400px;
  background: $color-white;
  box-shadow: $box-shadow;
  border-radius: 4px;
}

.panelCPlus-QC {
  background-image: $backgroundPanelCPlus;
  background-repeat: no-repeat;
  height: 239px;
}

.panelC-QC {
  background-image: $backgroundPanelC_R;
  background-repeat: no-repeat;
  height: 239px;
}

.title-QC {
  font-family: $font-primary;
  font-style: $font-style-normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 120%;
  color: $color-white;
}

.titleC-QC {
  font-family: $font-primary;
  font-style: $font-style-normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 120%;
  color: $color-white;
}

.panelCPlusLogo-QC {
  background-image: $panelCPlusLogo;
  background-repeat: no-repeat;
  height: 50px;
}

.panelCLogo-QC {
  background-image: $panelCLogo;
  background-repeat: no-repeat;
  height: 50px;
}

.panelPLogo-QC {
  background-image: $panelPLogo;
  background-repeat: no-repeat;
  height: 50px;
}

.panelTLogo-QC {
  background-image: $panelTLogo;
  background-repeat: no-repeat;
  height: 50px;
}

.panelLLogo-QC {
  background-image: $panelLLogo;
  background-repeat: no-repeat;
  height: 50px;
}

.panelSLogo-QC {
  background-image: $panelSLogo;
  background-repeat: no-repeat;
  height: 50px;
}

.rateMonth {
  font-family: $font-primary;
  font-style: $font-style-normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 120%;
  color: $color-white;
}

.rateMonthC {
  font-family: $font-primary;
  font-style: $font-style-normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 120%;
  color: $color-white;
}

.textMonth {
  font-family: $font-primary;
  font-style: $font-style-normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
  color: $color-white;
}

.textMonthC {
  font-family: $font-primary;
  font-style: $font-style-normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
  color: $color-white;
}

.textPreviousRateYear {
  font-family: $font-primary;
  font-style: $font-style-normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 120%;
  color: $color-white;
}

.textPreviousRateYearC {
  font-family: $font-primary;
  font-style: $font-style-normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 120%;
  color: $color-primary;
}

.rateYear {
  font-family: $font-primary;
  font-style: $font-style-normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 120%;
  color: $color-white;
}

.rateYearC {
  font-family: $font-primary;
  font-style: $font-style-normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 120%;
  color: $color-primary;
}

.textAfterRateYear {
  font-family: $font-primary;
  font-style: $font-style-normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
  color: $color-white;
}

.textAfterRateYearC {
  font-family: $font-primary;
  font-style: $font-style-normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
  color: $color-primary;
}

.containerTextSB-QC {
  font-family: $font-primary;
  font-style: $font-style-normal;
  font-weight: $font-weight-medium;
  font-size: 14px;
  line-height: 150%;
  color: $color-primary;
}

.textB-QC {
  font-family: $font-primary;
  font-style: $font-style-normal;
  font-weight: $font-weight-medium;
  font-size: 16px;
  line-height: 120%;
  color: $color-primary;
}
//#endregion
