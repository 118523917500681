.clickable-card {
  max-width: 332px;
  background: $color-white;
  /* VYV Shadow */
  box-shadow: $box-shadow-purple;
  border-radius: 4px;
  border-radius: 4px;
  font-family: 'Wigrum';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 150%;
  background: $color-white;
  color: $color-primary;

  &:hover,
  &:focus,
  &.selected,
  &.active {
    background: $color-primary;
    color: $color-white;
  }

  .two-lines {
    white-space: pre;
  }
}

.button-icon {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: $color-white;
  border: 2px solid $color-secondary;
  border-radius: 4px;
  height: 32px !important;
  width: 32px !important;
  font-family: $font-primary;
  font-style: $font-style-normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 150%;
  color: $color-secondary;
  padding: 0 !important;

  &:enabled:hover,
  &:enabled:active,
  &:enabled:focus {
    background: $color-white;
    color: $color-secondary;
    border-color: $color-white;
  }
}

.input-number {
  background: #ffffff;
  /* VYV Shadow */
  box-shadow: 0px 7px 28px rgba(98, 79, 131, 0.1);
  border-radius: 4px !important;
  font-family: 'Wigrum';
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 120%;
  width: 192px;
  text-align: center;
  &:focus,
  &:focus {
    outline: none;
  }

  padding: none !important;
  border: none !important;
  transition: none !important;
  -webkit-appearance: none !important;
  appearance: none !important;
  flex: none !important;
}

.p-calendar .p-inputtext {
  background-color: transparent;
  z-index: 1;
}

.calendarSrc {
  position: absolute;
  right: 0.9rem;
  top: 1.1rem;
  z-index: 0;
}

.p-datepicker-month,
.p-datepicker-year {
  text-decoration-line: underline;
  color: $color-secondary !important;
  padding: 0.5em;
}

.p-component-overlay {
  z-index: 2;
}
